import { handleActions } from 'redux-actions';
import {
  activateOtherOrderIMEIFailure,
  activateOtherOrderIMEIRequest,
  activateOtherOrderIMEISuccess,
  cancelInventoryRequestFailure,
  cancelInventoryRequestRequest,
  cancelInventoryRequestSuccess,
  checkingRatesFailure,
  checkingRatesRequest,
  checkingRatesSuccess,
  clearRequestData,
  createAgentOrderFailure,
  createAgentOrderRequest,
  createAgentOrderSuccess,
  estimateShippingRateFailure,
  estimateShippingRateRequest,
  estimateShippingRateSuccess,
  findCustomerOrderFailure,
  findCustomerOrderRequest,
  findCustomerOrderSuccess,
  fulfillCustomerOrderFailure,
  fulfillCustomerOrderRequest,
  fulfillCustomerOrderSuccess,
  fulfillHandoverOrderRequest,
  fulfillHandoverOrderSuccess,
  fulfillHandoverOrderFailure,
  fulfillOrderFailure,
  fulfillOrderRequest,
  fulfillOrderSuccess,
  getCarrierServicesSelectFailure,
  getCarrierServicesSelectRequest,
  getCarrierServicesSelectSuccess,
  getCustomerOrdersFailure,
  getCustomerOrdersRequest,
  getCustomerOrdersSuccess,
  getInventoryRequestFailure,
  getInventoryRequestListFailure,
  getInventoryRequestListRequest,
  getInventoryRequestListSuccess,
  getInventoryRequestRequest,
  getInventoryRequestSuccess,
  getOrderHistoryFailure,
  getOrderHistoryRequest,
  getOrderHistorySuccess,
  getSimCardWithIMEIFailure,
  getSimCardWithIMEIRequest,
  getSimCardWithIMEISuccess,
  pushCreatedInventoryToOrdersList,
  rejectInventoryOrdersFailure,
  rejectInventoryOrdersRequest,
  rejectInventoryOrdersSuccess,
  shipCustomerOrderFailure,
  shipCustomerOrderRequest,
  shipCustomerOrderSuccess,
  trackShippingFailure,
  trackShippingRequest,
  trackShippingSuccess,
  updateCustomerOrderLine,
  updateCustomerShippingAddressFailure,
  updateCustomerShippingAddressRequest,
  updateCustomerShippingAddressSuccess,
  finishCustomerOrderRequest,
  finishCustomerOrderSuccess,
  finishCustomerOrderFailure,
  handoverAndFulfillRequest,
  handoverAndFulfillSuccess,
  handoverAndFulfillFailure,
  handoverQuicklyRequest,
  handoverQuicklySuccess,
  handoverQuicklyFailure,
  getOrderLineWithDataRequest,
  getOrderLineWithDataSuccess,
  getOrderLineWithDataFailure,
  updateShippingRateFailure,
  updateShippingRateRequest,
  updateShippingRateSuccess,
  resubmitOrderRequest,
  resubmitOrderSuccess,
  resubmitOrderFailure,
  getInvoicesForLineRefundRequest,
  getInvoicesForLineRefundSuccess,
  getInvoicesForLineRefundFailure,
  refundLineAmountRequest,
  refundLineAmountSuccess,
  refundLineAmountFailure,
  updateOrderRequest,
  updateOrderSuccess,
  updateOrderFailure,
  deactivateLineRequest,
  deactivateLineSuccess,
  deactivateLineFailure,
  reactivateLineRequest,
  reactivateLineSuccess,
  reactivateLineFailure,
  removeLinePortRequest,
  removeLinePortSuccess,
  removeLinePortFailure,
  keepOldNumberRequest,
  keepOldNumberSuccess,
  keepOldNumberFailure,
  clearRefundData,
  getHotlinesRequest,
  getHotlinesSuccess,
  getHotlinesFailure,
  getDeactivatedLinesRequest,
  getDeactivatedLinesSuccess,
  getDeactivatedLinesFailure,
  removeLineRequest,
  removeLineSuccess,
  removeLineFailure,
  recreateBillingRequest,
  recreateBillingSuccess,
  recreateBillingFailure,
  createCombinedBillingRequest,
  createCombinedBillingSuccess,
  createCombinedBillingFailure,
  getCanceledLinesRequest,
  getCanceledLinesSuccess,
  getCanceledLinesFailure,
  abandonedAndFailedOrdersRequest,
  abandonedAndFailedOrdersSuccess,
  abandonedAndFailedOrdersFailure,
  activeLinesWithNoSubscriptionRequest,
  activeLinesWithNoSubscriptionSuccess,
  activeLinesWithNoSubscriptionFailure,
  getCustomerOrdersRequestById,
  getCustomerOrdersByIdSuccess,
  getCustomerOrdersByIdFailure,
  getDuplicatedCustomerAccountsRequest,
  getDuplicatedCustomerAccountsSuccess,
  getDuplicatedCustomerAccountsFailure,
  clearDuplicatedCustomersAccounts,
  changeShopifyLinePlanRequest,
  changeShopifyLinePlanSuccess,
  changeShopifyLinePlanFailure,
  changeShopifyOrderVariantRequest,
  changeShopifyOrderVariantSuccess,
  changeShopifyOrderVariantFailure,
  activateShopifyOtherLineRequest,
  activateShopifyOtherLineSuccess,
  activateShopifyOtherLineFailure,
  fulfillShopifyOrderRequest,
  fulfillShopifyOrderSuccess,
  fulfillShopifyOrderFailure,
  updateSimTypeRequest,
  updateSimTypeSuccess,
  updateSimTypeFailure,
  getSimRequestsRequest,
  getSimRequestsSuccess,
  getSimRequestsFailure,
  getSimRequestRequest,
  getSimRequestSuccess,
  getSimRequestFailure,
  shipRequestedSimRequest,
  shipRequestedSimSuccess,
  shipRequestedSimFailure,
} from './actions';

const initialState = {
  isGettingInventoryRequestList: true,
  isGettingInventoryRequestListSuccess: false,
  isGettingInventoryRequestListFailure: false,
  errorMessage: '',
  successMessage: '',
  inventoryRequestList: [],
  inventoryRequestCount: 0,
  isRejectingInventoryOrder: false,
  isRejectingInventoryOrderSuccess: false,
  isRejectingInventoryOrderFailure: false,
  isFulFillingOrder: false,
  isFulFillingOrderSuccess: false,
  isFulFillingOrderFailure: false,
  isCanceledInventoryRequestSuccess: false,
  isCanceledInventoryRequestFailure: false,
  isCreatingAgentOrder: false,
  isCreatingAgentOrderSuccess: false,
  isCreatingAgentOrderFailure: false,
  isGettingServicesSuccess: true,
  isGettingServicesFailure: true,
  servicesSelect: [],
  isGettingOrderHistory: true,
  isGettingOrderHistorySuccess: false,
  isGettingOrderHistoryFailure: false,
  orderHistoryList: [],
  orderHistoryCount: 0,
  isCheckingRates: false,
  isCheckingRatesSuccess: false,
  isCheckingRatesFailure: false,
  rates: [],
  ratesSelect: [],
  isTrackingShipment: true,
  isTrackingShipmentSuccess: false,
  isTrackingShipmentFailure: false,
  trackData: [],
  isGettingInventoryRequest: false,
  isGettingInventoryRequestSuccess: false,
  isGettingInventoryRequestFailure: false,
  inventoryRequest: {},
  isGettingCustomerOrders: false,
  isGettingCustomerOrdersSuccess: true,
  customerOrders: [],
  customerOrdersCount: 0,
  isGettingCustomerOrder: true,
  isGettingCustomerOrderSuccess: false,
  customerOrder: {},
  isUpdatingShippingAddress: false,
  isUpdatingShippingAddressSuccess: false,
  isUpdatingShippingAddressFailure: false,
  isEstimatingRate: false,
  isEstimatingRateSuccess: false,
  isEstimatingRateFailure: false,
  estimatedRate: {},
  isFulfillingCustomerOrder: false,
  isFulfillingCustomerOrderSuccess: false,
  isFulfillingCustomerOrderFailure: false,
  isFulfillHandoverOrder: false,
  isFulfillHandoverOrderSuccess: false,
  isFulfillHandoverOrderFailure: false,
  isActivatingOtherLineImei: false,
  isActivatingOtherLineImeiSuccess: false,
  isActivatingOtherLineImeiFailure: false,
  isShippingCustomerOrder: false,
  isShippingCustomerOrderSuccess: false,
  isShippingCustomerOrderFailure: false,
  isGettingSimCardWithIMEI: false,
  isGettingSimCardWithIMEISuccess: false,
  simCard: {},
  isCustomerOrderFinished: false,
  isCustomerOrderFinishedSuccess: false,
  isCustomerOrderFinishedFailure: false,
  isHandoverFromOrder: false,
  isHandoverFromOrderSuccess: false,
  isHandoverFromOrderFailure: false,
  isHandoverQuickly: false,
  isHandoverQuicklySuccess: false,
  isHandoverQuicklyFailure: false,
  isGettingOrderLine: false,
  isGettingOrderLineSuccess: false,
  isGettingOrderLineFailure: false,
  lineData: [],
  isUpdatingShippingRate: false,
  isUpdatingShippingRateSuccess: false,
  isUpdatingShippingRateFailure: false,
  isResubmittingOrder: false,
  isResubmittingOrderSuccess: false,
  isResubmittingOrderFailure: false,
  isGettingInvoicesForRefund: false,
  isGettingInvoicesForRefundSuccess: false,
  isGettingInvoicesForRefundFailure: false,
  refundInvoices: {},
  isRefundingLineAmount: false,
  isRefundingLineAmountSuccess: false,
  isRefundingLineAmountFailure: false,
  isUpdatingOrder: false,
  isUpdatingOrderSuccess: false,
  isUpdatingOrderFailure: false,
  shippingTab: null,
  paymentIntentLink: '',
  isDeactivatingLine: false,
  isDeactivatingLineSuccess: false,
  isDeactivatingLineFailure: false,
  warningMessage: '',
  newLine: {},
  isActivatingLine: false,
  isActivatingLineSuccess: false,
  isActivatingLineFailure: false,
  isRemovingLinePort: false,
  isRemovingLinePortSuccess: false,
  isRemovingLinePortFailure: false,
  isKeepingOldNumber: false,
  isKeepingOldNumberSuccess: false,
  isKeepingOldNumberFailure: false,
  isGettingHotlines: true,
  hotlines: [],
  hotlinesCount: 0,
  isGettingDeactivatedLines: true,
  deactivatedLines: [],
  deactivatedLinesCount: 0,
  isRemovingLine: false,
  isRemovingLineSuccess: false,
  isRemovingLineFailure: false,
  isRecreatingBilling: false,
  isRecreatingBillingSuccess: false,
  isRecreatingBillingFailure: false,
  isCreatingCombinedBilling: false,
  isCreatingCombinedBillingSuccess: false,
  isCreatingCombinedBillingFailure: false,
  isGettingCanceledOrders: false,
  isGettingCanceledOrdersSuccess: false,
  isGettingCanceledOrdersFailure: false,
  canceledOrders: [],
  canceledOrdersCount: 0,
  isGettingAbandonedAndFailureOrders: false,
  isGettingAbandonedAndFailureOrdersSuccess: false,
  isGettingAbandonedAndFailureOrdersFailure: false,
  abandonedAndFailureOrders: [],
  abandonedAndFailureOrdersCount: 0,
  isGettingActiveLinesWithNoSubscription: false,
  isGettingActiveLinesWithNoSubscriptionSuccess: false,
  isGettingActiveLinesWithNoSubscriptionFailure: false,
  activeLinesWithNoSubscription: [],
  activeLinesWithNoSubscriptionCount: 0,

  isGettingCustomerOrdersById: false,
  isGettingCustomerOrdersByIdSuccess: false,
  isGettingCustomerOrdersByIdFailure: false,
  customerOrdersById: [],
  customerOrdersCountById: 0,
  isGettingDuplicatedCustomers: true,
  duplicatedCustomerAccounts: [],
  isChangingShopifyLinePlan: false,
  isChangingShopifyLinePlanSuccess: false,
  isChangingShopifyLinePlanFailure: false,
  isChangingShopifyOrderVariant: false,
  isChangingShopifyOrderVariantSuccess: false,
  isChangingShopifyOrderVariantFailure: false,
  isActivatingShopifyOtherLine: false,
  isActivatingShopifyOtherLineSuccess: false,
  isActivatingShopifyOtherLineFailure: false,
  isFulfillingShopifyOrder: false,
  isFulfillingShopifyOrderSuccess: false,
  isFulfillingShopifyOrderFailure: false,
  isUpdateSimType: false,
  isUpdateSimTypeSuccess: false,
  isUpdateSimTypeFailure: false,
  simTypeUpdateData: {},
  isGettingSimRequests: true,
  isGettingSimRequestsSuccess: false,
  isGettingSimRequestsFailure: false,
  isGettingSimRequest: false,
  simRequest: {},
  isShippingRequestedSim: false,
  isShippingRequestedSimSuccess: false,
  isShippingRequestedSimFailure: false,
};

const reducer = handleActions(
  {
    [getInventoryRequestListRequest]: (state) => ({
      ...state,
      isGettingInventoryRequestList: true,
      isGettingInventoryRequestListSuccess: false,
      isGettingInventoryRequestListFailure: false,
    }),
    [getInventoryRequestListSuccess]: (state, { payload }) => ({
      ...state,
      isGettingInventoryRequestList: false,
      isGettingInventoryRequestListSuccess: true,
      successMessage: payload.message,
      inventoryRequestList: payload.data.rows,
      inventoryRequestCount: payload.data.count,
    }),
    [getInventoryRequestListFailure]: (state, { payload }) => ({
      ...state,
      isGettingInventoryRequestList: false,
      isGettingInventoryRequestListFailure: true,
      errorMessage: payload,
    }),
    [rejectInventoryOrdersRequest]: (state) => ({
      ...state,
      isRejectingInventoryOrder: true,
      isRejectingInventoryOrderSuccess: false,
      isRejectingInventoryOrderFailure: false,
    }),
    [rejectInventoryOrdersSuccess]: (state, { payload }) => ({
      ...state,
      isRejectingInventoryOrder: false,
      isRejectingInventoryOrderSuccess: true,
      successMessage: payload.message,
      inventoryRequestList: payload.data.rows,
      inventoryRequestCount: payload.data.count,
    }),
    [rejectInventoryOrdersFailure]: (state, { payload }) => ({
      ...state,
      isRejectingInventoryOrder: false,
      isRejectingInventoryOrderFailure: true,
      errorMessage: payload,
    }),
    [fulfillOrderRequest]: (state) => ({
      ...state,
      isFulFillingOrder: true,
      isFulFillingOrderSuccess: false,
      isFulFillingOrderFailure: false,
    }),
    [fulfillOrderSuccess]: (state, { payload }) => ({
      ...state,
      isFulFillingOrder: false,
      isFulFillingOrderSuccess: true,
      successMessage: payload.message,
      inventoryRequestList: payload.data.rows,
      inventoryRequestCount: payload.data.count,
    }),
    [fulfillOrderFailure]: (state, { payload }) => ({
      ...state,
      isFulFillingOrder: false,
      isFulFillingOrderFailure: true,
      errorMessage: payload,
    }),
    [pushCreatedInventoryToOrdersList]: (state, { payload }) => ({
      ...state,
      inventoryRequestList: [payload.data, ...state.inventoryRequestList],
      inventoryRequestCount: state.inventoryRequestCount + 1,
    }),
    [cancelInventoryRequestRequest]: (state) => ({
      ...state,
      isCanceledInventoryRequestSuccess: false,
      isCanceledInventoryRequestFailure: false,
    }),
    [cancelInventoryRequestSuccess]: (state, { payload }) => {
      const requestsCopy = [...state.inventoryRequestList];
      const index = requestsCopy.findIndex((i) => i.id === payload.data.id);
      if (index !== -1) {
        requestsCopy[index] = payload.data;
      }
      return {
        ...state,
        isCanceledInventoryRequestSuccess: true,
        isCanceledInventoryRequestFailure: false,
        successMessage: payload.message,
        inventoryRequestList: requestsCopy,
      };
    },
    [cancelInventoryRequestFailure]: (state, { payload }) => ({
      ...state,
      isCanceledInventoryRequestFailure: true,
      errorMessage: payload,
    }),
    [createAgentOrderRequest]: (state) => ({
      ...state,
      isCreatingAgentOrder: true,
      isCreatingAgentOrderSuccess: false,
      isCreatingAgentOrderFailure: false,
    }),
    [createAgentOrderSuccess]: (state, { payload }) => ({
      ...state,
      isCreatingAgentOrder: false,
      isCreatingAgentOrderSuccess: true,
      successMessage: payload.message,
      inventoryRequestList: state.inventoryRequestList.filter(
        (i) => i.id !== payload.data
      ),
      inventoryRequestCount: state.inventoryRequestCount - 1,
    }),
    [createAgentOrderFailure]: (state, { payload }) => ({
      ...state,
      isCreatingAgentOrder: false,
      isCreatingAgentOrderFailure: true,
      errorMessage: payload,
    }),
    [getCarrierServicesSelectRequest]: (state) => ({
      ...state,
      isGettingServicesSuccess: false,
      isGettingServicesFailure: false,
    }),
    [getCarrierServicesSelectSuccess]: (state, { payload }) => ({
      ...state,
      isGettingServicesSuccess: true,
      successMessage: payload.message,
      servicesSelect: payload.data,
    }),
    [getCarrierServicesSelectFailure]: (state, { payload }) => ({
      ...state,
      isGettingServicesFailure: true,
      errorMessage: payload,
    }),
    [getOrderHistoryRequest]: (state) => ({
      ...state,
      isGettingOrderHistory: true,
      isGettingOrderHistorySuccess: false,
      isGettingOrderHistoryFailure: false,
    }),
    [getOrderHistorySuccess]: (state, { payload }) => ({
      ...state,
      isGettingOrderHistory: false,
      isGettingOrderHistorySuccess: true,
      successMessage: payload.message,
      orderHistoryList: payload.data.rows,
      orderHistoryCount: payload.data.count,
    }),
    [getOrderHistoryFailure]: (state, { payload }) => ({
      ...state,
      isGettingOrderHistory: false,
      isGettingOrderHistoryFailure: true,
      errorMessage: payload,
    }),
    [checkingRatesRequest]: (state) => ({
      ...state,
      isCheckingRates: true,
      isCheckingRatesSuccess: false,
      isCheckingRatesFailure: false,
    }),
    [checkingRatesSuccess]: (state, { payload }) => ({
      ...state,
      isCheckingRates: false,
      isCheckingRatesSuccess: true,
      successMessage: payload.message,
      rates: payload.data.rates,
      ratesSelect: payload.data.ratesSelect,
    }),
    [checkingRatesFailure]: (state, { payload }) => ({
      ...state,
      isCheckingRates: false,
      isCheckingRatesFailure: true,
      errorMessage: payload,
    }),
    [trackShippingRequest]: (state) => ({
      ...state,
      isTrackingShipment: true,
      isTrackingShipmentSuccess: false,
      isTrackingShipmentFailure: false,
    }),
    [trackShippingSuccess]: (state, { payload }) => ({
      ...state,
      isTrackingShipment: false,
      isTrackingShipmentSuccess: true,
      successMessage: payload.message,
      trackData: payload.data,
    }),
    [trackShippingFailure]: (state, { payload }) => ({
      ...state,
      isTrackingShipment: false,
      isTrackingShipmentFailure: true,
      errorMessage: payload,
    }),
    [getInventoryRequestRequest]: (state) => ({
      ...state,
      isGettingInventoryRequest: true,
      isGettingInventoryRequestSuccess: false,
      isGettingInventoryRequestFailure: false,
    }),
    [getInventoryRequestSuccess]: (state, { payload }) => ({
      ...state,
      isGettingInventoryRequest: false,
      isGettingInventoryRequestSuccess: true,
      successMessage: payload.message,
      inventoryRequest: payload.data,
    }),
    [getInventoryRequestFailure]: (state, { payload }) => ({
      ...state,
      isGettingInventoryRequest: false,
      isGettingInventoryRequestFailure: true,
      errorMessage: payload,
    }),
    [getCustomerOrdersRequest]: (state) => ({
      ...state,
      isGettingCustomerOrders: true,
      isGettingCustomerOrdersSuccess: false,
      isGettingCustomerOrdersFailure: false,
    }),
    [getCustomerOrdersSuccess]: (state, { payload }) => ({
      ...state,
      isGettingCustomerOrders: false,
      isGettingCustomerOrdersSuccess: true,
      successMessage: payload.message,
      customerOrders: payload.data.rows,
      customerOrdersCount: payload.data.count,
    }),
    [getCustomerOrdersFailure]: (state, { payload }) => ({
      ...state,
      isGettingCustomerOrders: false,
      isGettingCustomerOrdersFailure: true,
      errorMessage: payload,
    }),
    [findCustomerOrderRequest]: (state) => ({
      ...state,
      isGettingCustomerOrder: true,
      isGettingCustomerOrderSuccess: false,
      isGettingCustomerOrderFailure: false,
      customerOrder: {},
    }),
    [findCustomerOrderSuccess]: (state, { payload }) => ({
      ...state,
      isGettingCustomerOrder: false,
      isGettingCustomerOrderSuccess: true,
      successMessage: payload.message,
      customerOrder: payload.data,
    }),
    [findCustomerOrderFailure]: (state, { payload }) => ({
      ...state,
      isGettingCustomerOrder: false,
      isGettingCustomerOrderFailure: true,
      errorMessage: payload,
    }),
    [updateCustomerShippingAddressRequest]: (state) => ({
      ...state,
      isUpdatingShippingAddress: true,
      isUpdatingShippingAddressSuccess: false,
      isUpdatingShippingAddressFailure: false,
    }),
    [updateCustomerShippingAddressSuccess]: (state, { payload }) => ({
      ...state,
      isUpdatingShippingAddress: false,
      isUpdatingShippingAddressSuccess: true,
      successMessage: payload.message,
    }),
    [updateCustomerShippingAddressFailure]: (state, { payload }) => ({
      ...state,
      isUpdatingShippingAddress: false,
      isUpdatingShippingAddressFailure: true,
      errorMessage: payload,
    }),
    [clearRequestData]: (state) => ({
      ...state,
      inventoryRequest: {},
    }),
    [estimateShippingRateRequest]: (state) => ({
      ...state,
      isEstimatingRate: true,
      isEstimatingRateSuccess: false,
      isEstimatingRateFailure: false,
    }),
    [estimateShippingRateSuccess]: (state, { payload }) => ({
      ...state,
      isEstimatingRate: false,
      isEstimatingRateSuccess: true,
      successMessage: payload.message,
      estimatedRate: payload.data,
    }),
    [estimateShippingRateFailure]: (state, { payload }) => ({
      ...state,
      isEstimatingRate: false,
      isEstimatingRateFailure: true,
      errorMessage: payload,
    }),
    [fulfillCustomerOrderRequest]: (state) => ({
      ...state,
      isFulfillingCustomerOrder: true,
      isFulfillingCustomerOrderSuccess: false,
      isFulfillingCustomerOrderFailure: false,
    }),
    [fulfillCustomerOrderSuccess]: (state, { payload }) => ({
      ...state,
      isFulfillingCustomerOrder: false,
      isFulfillingCustomerOrderSuccess: true,
      successMessage: payload.message,
    }),
    [fulfillCustomerOrderFailure]: (state, { payload }) => ({
      ...state,
      isFulfillingCustomerOrder: false,
      isFulfillingCustomerOrderFailure: true,
      errorMessage: payload,
    }),
    [fulfillHandoverOrderRequest]: (state) => ({
      ...state,
      isFulfillHandoverOrder: true,
      isFulfillHandoverOrderSuccess: false,
      isFulfillHandoverOrderFailure: false,
    }),
    [fulfillHandoverOrderSuccess]: (state, { payload }) => ({
      ...state,
      isFulfillHandoverOrder: false,
      isFulfillHandoverOrderSuccess: true,
      successMessage: payload.message,
      customerOrders: state.customerOrders.filter(
        (o) => o.id !== +payload.data.orderId
      ),
    }),
    [fulfillHandoverOrderFailure]: (state, { payload }) => ({
      ...state,
      isFulfillHandoverOrder: false,
      isFulfillHandoverOrderFailure: true,
      errorMessage: payload,
    }),
    [updateCustomerOrderLine]: (state, { payload }) => {
      const linesCopy = [...state.customerOrder.orderLines];
      const index = linesCopy.findIndex((i) => i.id === payload.line.id);
      if (index !== -1) {
        linesCopy[index] = payload.line;
        state.customerOrder.orderLines = linesCopy;
      }
      return {
        ...state,
        customerOrder: state.customerOrder,
      };
    },
    [activateOtherOrderIMEIRequest]: (state) => ({
      ...state,
      isActivatingOtherLineImei: true,
      isActivatingOtherLineImeiSuccess: false,
      isActivatingOtherLineImeiFailure: false,
    }),
    [activateOtherOrderIMEISuccess]: (state, { payload }) => {
      const customerOrderCopy = { ...state.customerOrder };
      const linesCopy = [...customerOrderCopy.others];
      const index = linesCopy.findIndex((i) => i.id === payload.data.id);
      if (index !== -1) {
        linesCopy[index] = payload.data;
        customerOrderCopy.others = linesCopy;
      }
      return {
        ...state,
        isActivatingOtherLineImei: false,
        isActivatingOtherLineImeiSuccess: true,
        successMessage: payload.message,
        customerOrder: customerOrderCopy,
      };
    },
    [activateOtherOrderIMEIFailure]: (state, { payload }) => ({
      ...state,
      isActivatingOtherLineImei: false,
      isActivatingOtherLineImeiFailure: true,
      errorMessage: payload,
    }),
    [shipCustomerOrderRequest]: (state) => ({
      ...state,
      isShippingCustomerOrder: true,
      isShippingCustomerOrderSuccess: false,
      isShippingCustomerOrderFailure: false,
    }),
    [shipCustomerOrderSuccess]: (state, { payload }) => ({
      ...state,
      isShippingCustomerOrder: false,
      isShippingCustomerOrderSuccess: true,
      successMessage: payload.message,
      customerOrders: state.customerOrders.filter(
        (i) => i.id !== payload.data.id
      ),
    }),
    [shipCustomerOrderFailure]: (state, { payload }) => ({
      ...state,
      isShippingCustomerOrder: false,
      isShippingCustomerOrderFailure: true,
      errorMessage: payload,
    }),
    [getSimCardWithIMEIRequest]: (state) => ({
      ...state,
      isGettingSimCardWithIMEI: true,
      isGettingSimCardWithIMEISuccess: false,
    }),
    [getSimCardWithIMEISuccess]: (state, { payload }) => ({
      ...state,
      isGettingSimCardWithIMEI: false,
      isGettingSimCardWithIMEISuccess: true,
      simCard: payload.data,
      successMessage: payload.message,
    }),
    [getSimCardWithIMEIFailure]: (state, { payload }) => ({
      ...state,
      isGettingSimCardWithIMEI: false,
      errorMessage: payload,
    }),
    [finishCustomerOrderRequest]: (state) => ({
      ...state,
      isCustomerOrderFinished: true,
      isCustomerOrderFinishedSuccess: false,
      isCustomerOrderFinishedFailure: false,
    }),
    [finishCustomerOrderSuccess]: (state, { payload }) => ({
      ...state,
      isCustomerOrderFinished: false,
      isCustomerOrderFinishedSuccess: true,
      successMessage: payload.message,
    }),
    [finishCustomerOrderFailure]: (state, { payload }) => ({
      ...state,
      isCustomerOrderFinished: false,
      isCustomerOrderFinishedFailure: true,
      errorMessage: payload,
    }),
    [handoverAndFulfillRequest]: (state) => ({
      ...state,
      isHandoverFromOrder: true,
      isHandoverFromOrderSuccess: false,
      isHandoverFromOrderFailure: false,
    }),
    [handoverAndFulfillSuccess]: (state, { payload }) => ({
      ...state,
      isHandoverFromOrder: false,
      isHandoverFromOrderSuccess: true,
      successMessage: payload.message,
    }),
    [handoverAndFulfillFailure]: (state, { payload }) => ({
      ...state,
      isHandoverFromOrder: false,
      isHandoverFromOrderFailure: true,
      errorMessage: payload,
    }),
    [handoverQuicklyRequest]: (state) => ({
      ...state,
      isHandoverQuickly: true,
      isHandoverQuicklySuccess: false,
      isHandoverQuicklyFailure: false,
    }),
    [handoverQuicklySuccess]: (state, { payload }) => ({
      ...state,
      isHandoverQuickly: false,
      isHandoverQuicklySuccess: true,
      successMessage: payload.message,
    }),
    [handoverQuicklyFailure]: (state, { payload }) => ({
      ...state,
      isHandoverQuickly: false,
      isHandoverQuicklyFailure: true,
      errorMessage: payload,
    }),
    [getOrderLineWithDataRequest]: (state) => ({
      ...state,
      isGettingOrderLine: true,
      isGettingOrderLineSuccess: false,
      isGettingOrderLineFailure: false,
    }),
    [getOrderLineWithDataSuccess]: (state, { payload }) => ({
      ...state,
      isGettingOrderLine: false,
      isGettingOrderLineSuccess: true,
      successMessage: payload.message,
      lineData: payload.data,
    }),
    [getOrderLineWithDataFailure]: (state, { payload }) => ({
      ...state,
      isGettingOrderLine: false,
      isGettingOrderLineFailure: true,
      errorMessage: payload,
    }),
    [updateShippingRateRequest]: (state) => ({
      ...state,
      isUpdatingShippingRate: true,
      isUpdatingShippingRateSuccess: false,
      isUpdatingShippingRateFailure: false,
    }),
    [updateShippingRateSuccess]: (state, { payload }) => ({
      ...state,
      isUpdatingShippingRate: false,
      isUpdatingShippingRateSuccess: true,
      successMessage: payload.message,
      customerOrder: payload.data,
    }),
    [updateShippingRateFailure]: (state, { payload }) => ({
      ...state,
      isUpdatingShippingRate: false,
      isUpdatingShippingRateFailure: true,
      errorMessage: payload,
    }),
    [resubmitOrderRequest]: (state) => ({
      ...state,
      isResubmittingOrder: true,
      isResubmittingOrderSuccess: false,
      isResubmittingOrderFailure: false,
    }),
    [resubmitOrderSuccess]: (state, { payload }) => {
      const ordersListCopy = [...state.customerOrders];
      const index = ordersListCopy.findIndex(
        (item) => item.id === payload.data.id
      );
      if (index !== -1) {
        ordersListCopy[index] = payload.data;
      }

      return {
        ...state,
        isResubmittingOrder: false,
        isResubmittingOrderSuccess: true,
        successMessage: payload.message,
        customerOrders: ordersListCopy,
        paymentIntentLink: payload.data?.hosted_invoice_url || '',
      };
    },
    [resubmitOrderFailure]: (state, { payload }) => ({
      ...state,
      isResubmittingOrder: false,
      isResubmittingOrderFailure: true,
      errorMessage: payload,
    }),
    [getInvoicesForLineRefundRequest]: (state) => ({
      ...state,
      isGettingInvoicesForRefund: true,
      isGettingInvoicesForRefundSuccess: false,
      isGettingInvoicesForRefundFailure: false,
    }),
    [getInvoicesForLineRefundSuccess]: (state, { payload }) => ({
      ...state,
      isGettingInvoicesForRefund: false,
      isGettingInvoicesForRefundSuccess: true,
      successMessage: payload.message,
      refundInvoices: payload.data,
    }),
    [getInvoicesForLineRefundFailure]: (state, { payload }) => ({
      ...state,
      isGettingInvoicesForRefund: false,
      isGettingInvoicesForRefundFailure: true,
      errorMessage: payload,
    }),
    [refundLineAmountRequest]: (state) => ({
      ...state,
      isRefundingLineAmount: true,
      isRefundingLineAmountSuccess: false,
      isRefundingLineAmountFailure: false,
    }),
    [refundLineAmountSuccess]: (state, { payload }) => ({
      ...state,
      isRefundingLineAmount: false,
      isRefundingLineAmountSuccess: true,
      successMessage: payload.message,
    }),
    [refundLineAmountFailure]: (state, { payload }) => ({
      ...state,
      isRefundingLineAmount: false,
      isRefundingLineAmountFailure: true,
      errorMessage: payload,
    }),
    [updateOrderRequest]: (state) => ({
      ...state,
      isUpdatingOrder: true,
      isUpdatingOrderSuccess: false,
      isUpdatingOrderFailure: false,
    }),
    [updateOrderSuccess]: (state, { payload }) => {
      const orderHistoryCopy = [...state.orderHistoryList];
      const index = orderHistoryCopy.findIndex(
        (history) => history.id === payload.data.id
      );
      if (index !== -1) {
        orderHistoryCopy[index] = payload.data;
      }
      return {
        ...state,
        isUpdatingOrder: false,
        isUpdatingOrderSuccess: true,
        successMessage: payload.message,
        orderHistoryList: orderHistoryCopy,
        shippingTab: payload.data.tracking_status === 'in_transit' ? 1 : 2,
      };
    },
    [updateOrderFailure]: (state, { payload }) => ({
      ...state,
      isUpdatingOrder: false,
      isUpdatingOrderFailure: true,
      errorMessage: payload,
    }),
    [deactivateLineRequest]: (state) => ({
      ...state,
      isDeactivatingLine: true,
      isDeactivatingLineSuccess: false,
      isDeactivatingLineFailure: false,
    }),
    [deactivateLineSuccess]: (state, { payload }) => ({
      ...state,
      isDeactivatingLine: false,
      isDeactivatingLineSuccess: true,
      successMessage: payload.message,
      warningMessage: payload.warning,
      newLine: payload.data,
    }),
    [deactivateLineFailure]: (state, { payload }) => ({
      ...state,
      isDeactivatingLine: false,
      isDeactivatingLineFailure: true,
      errorMessage: payload,
    }),
    [reactivateLineRequest]: (state) => ({
      ...state,
      isActivatingLine: true,
      isActivatingLineSuccess: false,
      isActivatingLineFailure: false,
    }),
    [reactivateLineSuccess]: (state, { payload }) => ({
      ...state,
      isActivatingLine: false,
      isActivatingLineSuccess: true,
      successMessage: payload.message,
      warningMessage: payload.warning,
      newLine: payload.data,
    }),
    [reactivateLineFailure]: (state, { payload }) => ({
      ...state,
      isActivatingLine: false,
      isActivatingLineFailure: true,
      errorMessage: payload,
    }),
    [removeLinePortRequest]: (state) => ({
      ...state,
      isRemovingLinePort: true,
      isRemovingLinePortSuccess: false,
      isRemovingLinePortFailure: false,
    }),
    [removeLinePortSuccess]: (state, { payload }) => ({
      ...state,
      isRemovingLinePort: false,
      isRemovingLinePortSuccess: true,
      successMessage: payload.message,
    }),
    [removeLinePortFailure]: (state, { payload }) => ({
      ...state,
      isRemovingLinePort: false,
      isRemovingLinePortFailure: true,
      errorMessage: payload,
    }),
    [keepOldNumberRequest]: (state) => ({
      ...state,
      isKeepingOldNumber: true,
      isKeepingOldNumberSuccess: false,
      isKeepingOldNumberFailure: false,
    }),
    [keepOldNumberSuccess]: (state, { payload }) => ({
      ...state,
      isKeepingOldNumber: false,
      isKeepingOldNumberSuccess: true,
      successMessage: payload.message,
    }),
    [keepOldNumberFailure]: (state, { payload }) => ({
      ...state,
      isKeepingOldNumber: false,
      isKeepingOldNumberFailure: true,
      errorMessage: payload,
    }),
    [clearRefundData]: (state) => ({
      ...state,
      refundInvoices: {},
    }),
    [getHotlinesRequest]: (state) => ({
      ...state,
      isGettingHotlines: true,
    }),
    [getHotlinesSuccess]: (state, { payload }) => ({
      ...state,
      isGettingHotlines: false,
      successMessage: payload.message,
      hotlines: payload.data.rows,
      hotlinesCount: payload.data.count,
    }),
    [getHotlinesFailure]: (state, { payload }) => ({
      ...state,
      isGettingHotlines: false,
      errorMessage: payload.message,
    }),
    [getDeactivatedLinesRequest]: (state) => ({
      ...state,
      isGettingDeactivatedLines: true,
    }),
    [getDeactivatedLinesSuccess]: (state, { payload }) => ({
      ...state,
      isGettingDeactivatedLines: false,
      deactivatedLines: payload.data.rows,
      deactivatedLinesCount: payload.data.count,
      successMessage: payload.message,
    }),
    [getDeactivatedLinesFailure]: (state, { payload }) => ({
      ...state,
      isGettingDeactivatedLines: false,
      errorMessage: payload,
    }),
    [removeLineRequest]: (state) => ({
      ...state,
      isRemovingLine: true,
      isRemovingLineSuccess: false,
      isRemovingLineFailure: false,
    }),
    [removeLineSuccess]: (state, { payload }) => ({
      ...state,
      isRemovingLine: false,
      isRemovingLineSuccess: true,
      successMessage: payload.message,
    }),
    [removeLineFailure]: (state, { payload }) => ({
      ...state,
      isRemovingLine: false,
      isRemovingLineFailure: true,
      errorMessage: payload,
    }),
    [recreateBillingRequest]: (state) => ({
      ...state,
      isRecreatingBilling: true,
      isRecreatingBillingSuccess: false,
      isRecreatingBillingFailure: false,
    }),
    [recreateBillingSuccess]: (state, { payload }) => ({
      ...state,
      isRecreatingBilling: false,
      isRecreatingBillingSuccess: true,
      successMessage: payload.message,
    }),
    [recreateBillingFailure]: (state, { payload }) => ({
      ...state,
      isRecreatingBilling: false,
      isRecreatingBillingFailure: true,
      errorMessage: payload,
    }),
    [createCombinedBillingRequest]: (state) => ({
      ...state,
      isCreatingCombinedBilling: true,
      isCreatingCombinedBillingSuccess: false,
      isCreatingCombinedBillingFailure: false,
    }),
    [createCombinedBillingSuccess]: (state, { payload }) => ({
      ...state,
      isCreatingCombinedBilling: false,
      isCreatingCombinedBillingSuccess: true,
      successMessage: payload.message,
    }),
    [createCombinedBillingFailure]: (state, { payload }) => ({
      ...state,
      isCreatingCombinedBilling: false,
      isCreatingCombinedBillingFailure: true,
      errorMessage: payload,
    }),
    [getCanceledLinesRequest]: (state, { payload }) => ({
      ...state,
      isGettingCanceledOrders: true,
      isGettingCanceledOrdersSuccess: false,
      isGettingCanceledOrdersFailure: false,
    }),
    [getCanceledLinesSuccess]: (state, { payload }) => ({
      ...state,
      isGettingCanceledOrders: false,
      isGettingCanceledOrdersSuccess: true,
      successMessage: payload.message,
      canceledOrders: payload.data.rows,
      canceledOrdersCount: payload.data.count,
    }),
    [getCanceledLinesFailure]: (state, { payload }) => ({
      ...state,
      isGettingCanceledOrders: false,
      isGettingCanceledOrdersFailure: true,
      errorMessage: payload,
    }),
    [abandonedAndFailedOrdersRequest]: (state) => ({
      ...state,
      isGettingAbandonedAndFailureOrders: true,
      isGettingAbandonedAndFailureOrdersSuccess: false,
      isGettingAbandonedAndFailureOrdersFailure: false,
    }),
    [abandonedAndFailedOrdersSuccess]: (state, { payload }) => ({
      ...state,
      isGettingAbandonedAndFailureOrders: false,
      isGettingAbandonedAndFailureOrdersSuccess: true,
      successMessage: payload.message,
      abandonedAndFailureOrders: payload.data.rows,
      abandonedAndFailureOrdersCount: payload.data.count,
    }),
    [abandonedAndFailedOrdersFailure]: (state, { payload }) => ({
      ...state,
      isGettingAbandonedAndFailureOrders: false,
      isGettingAbandonedAndFailureOrdersFailure: true,
      errorMessage: payload,
    }),
    [activeLinesWithNoSubscriptionRequest]: (state) => ({
      ...state,
      isGettingActiveLinesWithNoSubscription: true,
      isGettingActiveLinesWithNoSubscriptionSuccess: false,
      isGettingActiveLinesWithNoSubscriptionFailure: false,
    }),
    [activeLinesWithNoSubscriptionSuccess]: (state, { payload }) => ({
      ...state,
      isGettingActiveLinesWithNoSubscription: false,
      isGettingActiveLinesWithNoSubscriptionSuccess: true,
      successMessage: payload.message,
      activeLinesWithNoSubscription: payload.data.rows,
      activeLinesWithNoSubscriptionCount: payload.data.count,
    }),
    [activeLinesWithNoSubscriptionFailure]: (state, { payload }) => ({
      ...state,
      isGettingActiveLinesWithNoSubscription: false,
      isGettingActiveLinesWithNoSubscriptionFailure: true,
      errorMessage: payload,
    }),
    [getCustomerOrdersRequestById]: (state) => ({
      ...state,
      isGettingCustomerOrdersById: true,
      isGettingCustomerOrdersByIdSuccess: false,
      isGettingCustomerOrdersByIdFailure: false,
    }),
    [getCustomerOrdersByIdSuccess]: (state, { payload }) => ({
      ...state,
      isGettingCustomerOrdersById: false,
      isGettingCustomerOrdersByIdSuccess: true,
      successMessage: payload.message,
      customerOrdersById: payload.data.rows,
      customerOrdersCountById: payload.data.count,
    }),
    [getCustomerOrdersByIdFailure]: (state, { payload }) => ({
      ...state,
      isGettingCustomerOrdersById: false,
      isGettingCustomerOrdersByIdFailure: true,
      errorMessage: payload,
    }),
    [getDuplicatedCustomerAccountsRequest]: (state) => ({
      ...state,
      isGettingDuplicatedCustomers: true,
      duplicatedCustomerAccounts: [],
    }),
    [getDuplicatedCustomerAccountsSuccess]: (state, { payload }) => ({
      ...state,
      isGettingDuplicatedCustomers: false,
      duplicatedCustomerAccounts: payload.data,
      successMessage: payload.message,
    }),
    [getDuplicatedCustomerAccountsFailure]: (state, { payload }) => ({
      ...state,
      isGettingDuplicatedCustomers: false,
      errorMessage: payload,
    }),
    [clearDuplicatedCustomersAccounts]: (state) => ({
      ...state,
      duplicatedCustomerAccounts: [],
      isGettingDuplicatedCustomers: false,
    }),
    [changeShopifyLinePlanRequest]: (state) => ({
      ...state,
      isChangingShopifyLinePlan: true,
      isChangingShopifyLinePlanSuccess: false,
      isChangingShopifyLinePlanFailure: false,
    }),
    [changeShopifyLinePlanSuccess]: (state, { payload }) => ({
      ...state,
      isChangingShopifyLinePlan: false,
      isChangingShopifyLinePlanSuccess: true,
      successMessage: payload.message,
    }),
    [changeShopifyLinePlanFailure]: (state, { payload }) => ({
      ...state,
      isChangingShopifyLinePlan: false,
      isChangingShopifyLinePlanFailure: true,
      errorMessage: payload,
    }),
    [changeShopifyOrderVariantRequest]: (state) => ({
      ...state,
      isChangingShopifyOrderVariant: true,
      isChangingShopifyOrderVariantSuccess: false,
      isChangingShopifyOrderVariantFailure: false,
    }),
    [changeShopifyOrderVariantSuccess]: (state, { payload }) => ({
      ...state,
      isChangingShopifyOrderVariant: false,
      isChangingShopifyOrderVariantSuccess: true,
      successMessage: payload.message,
    }),
    [changeShopifyOrderVariantFailure]: (state, { payload }) => ({
      ...state,
      isChangingShopifyOrderVariant: false,
      isChangingShopifyOrderVariantFailure: true,
      errorMessage: payload,
    }),
    [activateShopifyOtherLineRequest]: (state) => ({
      ...state,
      isActivatingShopifyOtherLine: true,
      isActivatingShopifyOtherLineSuccess: false,
      isActivatingShopifyOtherLineFailure: false,
    }),
    [activateShopifyOtherLineSuccess]: (state, { payload }) => ({
      ...state,
      isActivatingShopifyOtherLine: false,
      isActivatingShopifyOtherLineSuccess: true,
      successMessage: payload.message,
    }),
    [activateShopifyOtherLineFailure]: (state, { payload }) => ({
      ...state,
      isActivatingShopifyOtherLine: false,
      isActivatingShopifyOtherLineFailure: true,
      errorMessage: payload,
    }),
    [fulfillShopifyOrderRequest]: (state) => ({
      ...state,
      isFulfillingShopifyOrder: true,
      isFulfillingShopifyOrderSuccess: false,
      isFulfillingShopifyOrderFailure: false,
    }),
    [fulfillShopifyOrderSuccess]: (state, { payload }) => ({
      ...state,
      isFulfillingShopifyOrder: false,
      isFulfillingShopifyOrderSuccess: true,
      successMessage: payload.message,
    }),
    [fulfillShopifyOrderFailure]: (state, { payload }) => ({
      ...state,
      isFulfillingShopifyOrder: false,
      isFulfillingShopifyOrderFailure: true,
      errorMessage: payload,
    }),
    [updateSimTypeRequest]: (state) => ({
      ...state,
      isUpdateSimType: true,
      isUpdateSimTypeSuccess: false,
      isUpdateSimTypeFailure: false,
    }),
    [updateSimTypeSuccess]: (state, { payload }) => ({
      ...state,
      isUpdateSimType: false,
      isUpdateProductCartSuccess: true,
      successMessage: payload.message,
      simTypeUpdateData: payload.data,
    }),
    [updateSimTypeFailure]: (state, { payload }) => ({
      ...state,
      isUpdateSimType: false,
      isUpdateSimTypeFailure: true,
      errorMessage: payload,
    }),
    [getSimRequestsRequest]: (state) => ({
      ...state,
      isGettingSimRequests: true,
      isGettingSimRequestsSuccess: false,
      isGettingSimRequestsFailure: false,
    }),
    [getSimRequestsSuccess]: (state, { payload }) => ({
      ...state,
      isGettingSimRequests: false,
      isGettingSimRequestsSuccess: true,
      successMessage: payload.message,
      simRequests: payload.data.rows,
      simRequestsCount: payload.data.count,
    }),
    [getSimRequestsFailure]: (state, { payload }) => ({
      ...state,
      isGettingSimRequests: false,
      isGettingSimRequestsFailure: true,
      errorMessage: payload,
    }),
    [getSimRequestRequest]: (state, { payload }) => ({
      ...state,
      isGettingSimRequest: true,
    }),
    [getSimRequestSuccess]: (state, { payload }) => ({
      ...state,
      isGettingSimRequest: false,
      successMessage: payload.message,
      simRequest: payload.data,
    }),
    [getSimRequestFailure]: (state, { payload }) => ({
      ...state,
      isGettingSimRequest: false,
      errorMessage: payload,
    }),
    [shipRequestedSimRequest]: (state) => ({
      ...state,
      isShippingRequestedSim: true,
      isShippingRequestedSimSuccess: false,
      isShippingRequestedSimFailure: false,
    }),
    [shipRequestedSimSuccess]: (state, { payload }) => ({
      ...state,
      isShippingRequestedSim: false,
      isShippingRequestedSimSuccess: true,
      successMessage: payload.message,
    }),
    [shipRequestedSimFailure]: (state, { payload }) => ({
      ...state,
      isShippingRequestedSim: false,
      isShippingRequestedSimFailure: true,
      errorMessage: payload,
    }),
  },
  initialState
);

export default reducer;
