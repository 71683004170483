import { axiosApiInstance, config } from 'custom-configs';

import {
  activateSIMMDNPlanFailure,
  activateSIMMDNPlanRequest,
  activateSIMMDNPlanSuccess,
  createCarrierFailure,
  createCarrierRequest,
  createCarrierSuccess,
  deleteCarrierFailure,
  deleteCarrierRequest,
  deleteCarrierSuccess,
  deleteCarrierFileRequest,
  deleteCarrierFileSuccess,
  deleteCarrierFileFailure,
  enableIsFreeServiceFailure,
  enableIsFreeServiceRequest,
  enableIsFreeServiceSuccess,
  getCarriersListDataFailure,
  getCarriersListDataRequest,
  getCarriersListDataSuccess,
  getCarrierRequest,
  getCarrierSuccess,
  getCarrierFailure,
  getCarriersServicesFailure,
  getCarriersServicesRequest,
  getCarriersServicesSuccess,
  getExternalAPIsFailure,
  getExternalAPIsRequest,
  getExternalAPIsSuccess,
  getShippingCarriersSelectFailure,
  getShippingCarriersSelectRequest,
  getShippingCarriersSelectSuccess,
  toggleCarrierServiceFailure,
  toggleCarrierServiceRequest,
  toggleCarrierServiceSuccess,
  updateCarrierFailure,
  updateCarrierRequest,
  updateCarrierSuccess,
  getPortInRequest,
  getPortInSuccess,
  getPortInFailure,
  checkPortInStatusSuccess,
  checkPortInStatusFailure,
  checkPortInStatusRequest,
  createManuallyPortInSuccess,
  createManuallyPortInFailure,
  createManuallyPortInRequest,
  cancelPortInSuccess,
  cancelPortInFailure,
  cancelPortInRequest,
  activateFWCSuccess,
  activateFWCFailure,
  activateFWCRequest,
  validateShippingRatesSuccess,
  validateShippingRatesFailure,
  validateShippingRatesRequest,
  changePlanSuccess,
  changePlanFailure,
  changePlanRequest,
  generateShippingPriceSuccess,
  generateShippingPriceFailure,
  generateShippingPriceRequest,
  updatePortSuccess,
  updatePortFailure,
  updatePortRequest,
  resubmitPortSuccess,
  resubmitPortFailure,
  resubmitPortRequest,
  activateHotlineSuccess,
  activateHotlineFailure,
  activateHotlineRequest,
  removeHotlineSuccess,
  removeHotlineFailure,
  removeHotlineRequest,
  refreshLineSuccess,
  refreshLineFailure,
  refreshLineRequest,
  portWithNewESNSuccess,
  portWithNewESNFailure,
  portWithNewESNRequest,
  changeMDNAtCarrierSuccess,
  changeMDNAtCarrierFailure,
  changeMDNAtCarrierRequest,
  cancelLinePortSuccess,
  cancelLinePortFailure,
  cancelLinePortRequest,
  changeCostPlanSuccess,
  changeCostPlanFailure,
  changeCostPlanRequest,
  getCDRReportSuccess,
  getCDRReportFailure,
  getCDRReportRequest,
  downloadCDRZipSuccess,
  downloadCDRZipFailure,
  downloadCDRZipRequest,
  uploadCDRSuccess,
  uploadCDRFailure,
  uploadCDRRequest,
  syncSubscriptionDatesSuccess,
  syncSubscriptionDatesFailure,
  syncSubscriptionDatesRequest,
  activateShopifyLineSuccess,
  activateShopifyLineFailure,
  activateShopifyLineRequest,
  bulkChangeCostPlanSuccess,
  bulkChangeCostPlanFailure,
  bulkChangeCostPlanRequest,
  submitCostPlanToPWGSuccess,
  submitCostPlanToPWGFailure,
  submitCostPlanToPWGRequest,
  resetCostPlanOptimizationSuccess,
  resetCostPlanOptimizationFailure,
  resetCostPlanOptimizationRequest,
} from './actions';

import { call, put, takeLatest } from 'redux-saga/effects';
import { catchResponseMessages } from 'utils/methods';
import { updateCustomerOrderLine } from '../eCommerce/orders/actions';

const URL = `${config.TENANT}/carriers`;

function* createCarrier({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(
        `${payload.type === 'acp' ? URL : URL + '/shipping'}/create`,
        payload.values
      )
    );
    if (response?.status === 200) {
      yield put(createCarrierSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for createCarrier, error`, e);
    if (e?.response?.data) {
      yield put(createCarrierFailure(catchResponseMessages(e)));
    }
  }
}

function* getExternalAPIs() {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL}/external-apis`)
    );
    if (response?.status === 200) {
      yield put(getExternalAPIsSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for getExternalAPIs, error`, e);
    if (e?.response?.data) {
      yield put(getExternalAPIsFailure(catchResponseMessages(e)));
    }
  }
}

function* getCarriersListData({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(
        `${payload.type === 'acp' ? URL : URL + '/shipping'}/list-data${
          payload.query
        }`
      )
    );
    if (response?.status === 200) {
      yield put(getCarriersListDataSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for getCarriersListData, error`, e);
    if (e?.response?.data) {
      yield put(getCarriersListDataFailure(catchResponseMessages(e)));
    }
  }
}

function* getCarrier({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(
        `${payload.type === 'acp' ? URL : URL + '/shipping'}/${payload.id}`
      )
    );

    if (response?.status === 200) {
      yield put(getCarrierSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for getCarrier, error`, e);
    if (e?.response?.data) {
      yield put(getCarrierFailure(catchResponseMessages(e)));
    }
  }
}

function* deleteCarrier({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.delete(
        `${payload.type === 'acp' ? URL : URL + '/shipping'}/delete-carrier/${
          payload.id
        }`
      )
    );
    if (response?.status === 200) {
      yield put(deleteCarrierSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for deleteCarrier, error`, e);
    if (e?.response?.data) {
      yield put(deleteCarrierFailure(catchResponseMessages(e)));
    }
  }
}

function* deleteCarrierFile({ payload }) {
  try {
    const { type, id, fileId } = payload;
    const response = yield call(() =>
      axiosApiInstance.delete(
        `${
          type === 'acp' ? URL : URL + '/shipping'
        }/delete-carrier-file/${id}/${fileId}`
      )
    );
    if (response?.status === 200) {
      yield put(deleteCarrierFileSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for deleteCarrierFile, error`, e);
    if (e?.response?.data) {
      yield put(deleteCarrierFileFailure(catchResponseMessages(e)));
    }
  }
}

function* updateCarrier({ payload }) {
  try {
    const { type, id, data } = payload;
    const response = yield call(() =>
      axiosApiInstance.put(
        `${type === 'acp' ? URL : URL + '/shipping'}/update-carrier/${id}`,
        data
      )
    );

    if (response?.status === 200) {
      yield put(updateCarrierSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for updateCarrier, error`, e);
    if (e?.response?.data) {
      yield put(updateCarrierFailure(catchResponseMessages(e)));
    }
  }
}

function* carrierServices({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(
        `${URL}/shipping/services/${payload.carrierId}${payload.query}`
      )
    );
    if (response?.status === 200) {
      yield put(getCarriersServicesSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for carrierServices, error`, e);
    if (e?.response?.data) {
      yield put(getCarriersServicesFailure(catchResponseMessages(e)));
    }
  }
}

function* shippingCarriersSelect() {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL}/shipping/select`)
    );
    if (response?.status === 200) {
      yield put(getShippingCarriersSelectSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for shippingCarriersSelect, error`, e);
    if (e?.response?.data) {
      yield put(getShippingCarriersSelectFailure(catchResponseMessages(e)));
    }
  }
}
function* toggleService({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.put(
        `${URL}/shipping/toggle/${payload.id}${payload.query}`
      )
    );
    if (response?.status === 200) {
      yield put(toggleCarrierServiceSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for toggleService, error`, e);
    if (e?.response?.data) {
      yield put(toggleCarrierServiceFailure(catchResponseMessages(e)));
    }
  }
}
function* enableIsFree({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.put(`${URL}/shipping/enable-free-service/${payload.id}`)
    );
    if (response?.status === 200) {
      yield put(enableIsFreeServiceSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for enableIsFree, error`, e);
    if (e?.response?.data) {
      yield put(enableIsFreeServiceFailure(catchResponseMessages(e)));
    }
  }
}
function* activateSIM({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(`${URL}/activate-sim-esn`, payload)
    );
    if (response?.status === 200) {
      yield put(activateSIMMDNPlanSuccess(response.data));
      yield put(updateCustomerOrderLine(response.data.data));
    }
  } catch (e) {
    console.log(`Catch for activateSIM, error`, e);
    if (e?.response?.data) {
      yield put(activateSIMMDNPlanFailure(catchResponseMessages(e)));
    }
  }
}

function* getPortIn({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL}/port-in${payload}`)
    );

    if (response?.status === 200) {
      yield put(getPortInSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for getPortIn, error`, e);
    if (e?.response?.data) {
      yield put(getPortInFailure(catchResponseMessages(e)));
    }
  }
}

function* checkPortInStatus({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL}/check-port-in/${payload}`)
    );

    if (response?.status === 200) {
      yield put(checkPortInStatusSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for checkPortInStatus, error`, e);
    if (e?.response?.data) {
      yield put(checkPortInStatusFailure(catchResponseMessages(e)));
    }
  }
}

function* createManuallyPortin({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(
        `${URL}/manually-portin/${payload.orderId}/${payload.customerId}/${payload.retailPlanId}/${payload.lineId}`,
        payload.data
      )
    );

    if (response?.status === 200) {
      yield put(createManuallyPortInSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for createManuallyPortin, error`, e);
    if (e?.response?.data) {
      yield put(createManuallyPortInFailure(catchResponseMessages(e)));
    }
  }
}

function* cancelPortin({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.patch(`${URL}/cancel-port/${payload}`)
    );

    if (response?.status === 200) {
      yield put(cancelPortInSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for cancelPortin, error`, e);
    if (e?.response?.data) {
      yield put(cancelPortInFailure(catchResponseMessages(e)));
    }
  }
}
function* activateWFC({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.patch(
        `${URL}/activate-wfc/${payload.lineId}`,
        payload.data
      )
    );

    if (response?.status === 200) {
      yield put(activateFWCSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for activateWFC, error`, e);
    if (e?.response?.data) {
      yield put(activateFWCFailure(catchResponseMessages(e)));
    }
  }
}
function* validateRates({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(
        `${URL}/shipping/validate-rates/${payload.orderId}/${payload.customerId}${payload.query}`
      )
    );

    if (response?.status === 200) {
      yield put(validateShippingRatesSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for validateRates, error`, e);
    if (e?.response?.data) {
      yield put(validateShippingRatesFailure(catchResponseMessages(e)));
    }
  }
}
function* changePlan({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.put(
        `${URL}/change-plan/${payload.lineId}/${payload.planId}`,
        payload.data
      )
    );

    if (response?.status === 200) {
      yield put(changePlanSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for changePlan, error`, e);
    if (e?.response?.data) {
      yield put(changePlanFailure(catchResponseMessages(e)));
    }
  }
}

function* generateShippingRate({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(`${URL}/shipping/generate-rate`, payload)
    );

    if (response?.status === 200) {
      yield put(generateShippingPriceSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for generateShippingRate, error`, e);
    if (e?.response?.data) {
      yield put(generateShippingPriceFailure(catchResponseMessages(e)));
    }
  }
}

function* updatePort({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.patch(
        `${URL}/update-port/${payload.portId}`,
        payload.data
      )
    );

    if (response?.status === 200) {
      yield put(updatePortSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for updatePort, error`, e);
    if (e?.response?.data) {
      yield put(updatePortFailure(catchResponseMessages(e)));
    }
  }
}

function* resubmitPort({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.put(
        `${URL}/resubmit-port/${payload.portId}`,
        payload.data
      )
    );

    if (response?.status === 200) {
      yield put(resubmitPortSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for resubmitPort, error`, e);
    if (e?.response?.data) {
      yield put(resubmitPortFailure(catchResponseMessages(e)));
    }
  }
}

function* activatingHotline({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(`${URL}/activate-hotline/${payload.lineId}`)
    );

    if (response?.status === 200) {
      yield put(activateHotlineSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for activatingHotline, error`, e);
    if (e?.response?.data) {
      yield put(activateHotlineFailure(catchResponseMessages(e)));
    }
  }
}

function* removeHotline({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.delete(`${URL}/remove-hotline/${payload.lineId}`)
    );

    if (response?.status === 200) {
      yield put(removeHotlineSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for removeHotline, error`, e);
    if (e?.response?.data) {
      yield put(removeHotlineFailure(catchResponseMessages(e)));
    }
  }
}

function* refreshLine({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.put(`${URL}/refresh-line/${payload.lineId}`)
    );

    if (response?.status === 200) {
      yield put(refreshLineSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for refreshLine, error`, e);
    if (e?.response?.data) {
      yield put(refreshLineFailure(catchResponseMessages(e)));
    }
  }
}

function* portWithSameESN({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(
        `${URL}/port-with-same-esn/${payload.lineId}`,
        payload.data
      )
    );

    if (response?.status === 200) {
      yield put(portWithNewESNSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for portWithSameESN, error`, e);
    if (e?.response?.data) {
      yield put(portWithNewESNFailure(catchResponseMessages(e)));
    }
  }
}

function* changeMDNAtCarrier({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(
        `${URL}/change-mdn-at-carrier/${payload.lineId}`,
        payload.data
      )
    );

    if (response?.status === 200) {
      yield put(changeMDNAtCarrierSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for changeMDNAtCarrier, error`, e);
    if (e?.response?.data) {
      yield put(changeMDNAtCarrierFailure(catchResponseMessages(e)));
    }
  }
}

function* cancelLinePort({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.delete(
        `${URL}/cancel-line-port/${payload.lineId}/${payload.portId}`,
        payload.data
      )
    );

    if (response?.status === 200) {
      yield put(cancelLinePortSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for cancelLinePort, error`, e);
    if (e?.response?.data) {
      yield put(cancelLinePortFailure(catchResponseMessages(e)));
    }
  }
}

function* changeCostPlan({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(
        `${URL}/change-cost-plan/${payload.lineId}/${payload.apsId}`
      )
    );

    if (response?.status === 200) {
      yield put(changeCostPlanSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for changeMDNAtCarrier, error`, e);
    if (e?.response?.data) {
      yield put(changeCostPlanFailure(catchResponseMessages(e)));
    }
  }
}

function* getCDRData({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL}/call-record-data${payload}`)
    );

    if (response?.status === 200) {
      yield put(getCDRReportSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for getCDRData, error`, e);
    if (e?.response?.data) {
      yield put(getCDRReportFailure(catchResponseMessages(e)));
    }
  }
}

function* downloadCDR({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.get(`${URL}/download-cdr-data${payload}`)
    );

    if (response?.status === 200) {
      yield put(downloadCDRZipSuccess(response.data));
      if (response.data?.data?.path) {
        window.open(response.data.data.path, '_self');
      }
    }
  } catch (e) {
    console.log(`Catch for downloadCDR, error`, e);
    if (e?.response?.data) {
      yield put(downloadCDRZipFailure(catchResponseMessages(e)));
    }
  }
}

function* uploadCDR({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(`${URL}/upload-cdr-data`, payload)
    );

    if (response?.status === 200) {
      yield put(uploadCDRSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for uploadCDR, error`, e);
    if (e?.response?.data) {
      yield put(uploadCDRFailure(catchResponseMessages(e)));
    }
  }
}

function* syncSubscriptions({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(`${URL}/sync-subscriptions`, payload)
    );

    if (response?.status === 200) {
      yield put(syncSubscriptionDatesSuccess(response.data));
      if (response.data?.data?.path) {
        window.open(response.data?.data?.path, '_self');
      }
    }
  } catch (e) {
    console.log(`Catch for syncSubscriptions, error`, e);
    if (e?.response?.data) {
      yield put(syncSubscriptionDatesFailure(catchResponseMessages(e)));
    }
  }
}

function* activateShopifyLine({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(`${URL}/activate-shopify-line`, payload)
    );

    if (response?.status === 200) {
      yield put(activateShopifyLineSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for activateShopifyLine, error`, e);
    if (e?.response?.data) {
      yield put(activateShopifyLineFailure(catchResponseMessages(e)));
    }
  }
}

function* bulkChangeCostPlan({ payload }) {
  try {
    const response = yield call(() =>
      axiosApiInstance.post(`${URL}/bulk-change-cost-plan`, payload)
    );

    if (response?.status === 200) {
      yield put(bulkChangeCostPlanSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for bulkChangeCostPlan, error`, e);
    if (e?.response?.data) {
      yield put(bulkChangeCostPlanFailure(catchResponseMessages(e)));
    }
  }
}

function* submitCostPlanToPWG() {
  try {
    const response = yield call(() =>
      axiosApiInstance.patch(`${URL}/submit-cost-plan-pwg`)
    );

    if (response?.status === 200) {
      yield put(submitCostPlanToPWGSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for submitCostPlanToPWG, error`, e);
    if (e?.response?.data) {
      yield put(submitCostPlanToPWGFailure(catchResponseMessages(e)));
    }
  }
}

function* resetCostPlanOptimization() {
  try {
    const response = yield call(() =>
      axiosApiInstance.patch(`${URL}/reset-cost-plan`)
    );

    if (response?.status === 200) {
      yield put(resetCostPlanOptimizationSuccess(response.data));
    }
  } catch (e) {
    console.log(`Catch for resetCostPlanOptimization, error`, e);
    if (e?.response?.data) {
      yield put(resetCostPlanOptimizationFailure(catchResponseMessages(e)));
    }
  }
}

export default function* () {
  yield takeLatest(createCarrierRequest, createCarrier);
  yield takeLatest(getExternalAPIsRequest, getExternalAPIs);
  yield takeLatest(getCarriersListDataRequest, getCarriersListData);
  yield takeLatest(getCarrierRequest, getCarrier);
  yield takeLatest(deleteCarrierRequest, deleteCarrier);
  yield takeLatest(deleteCarrierFileRequest, deleteCarrierFile);
  yield takeLatest(updateCarrierRequest, updateCarrier);
  yield takeLatest(getCarriersServicesRequest, carrierServices);
  yield takeLatest(getShippingCarriersSelectRequest, shippingCarriersSelect);
  yield takeLatest(toggleCarrierServiceRequest, toggleService);
  yield takeLatest(enableIsFreeServiceRequest, enableIsFree);
  yield takeLatest(activateSIMMDNPlanRequest, activateSIM);
  yield takeLatest(getPortInRequest, getPortIn);
  yield takeLatest(checkPortInStatusRequest, checkPortInStatus);
  yield takeLatest(createManuallyPortInRequest, createManuallyPortin);
  yield takeLatest(cancelPortInRequest, cancelPortin);
  yield takeLatest(activateFWCRequest, activateWFC);
  yield takeLatest(validateShippingRatesRequest, validateRates);
  yield takeLatest(changePlanRequest, changePlan);
  yield takeLatest(generateShippingPriceRequest, generateShippingRate);
  yield takeLatest(updatePortRequest, updatePort);
  yield takeLatest(resubmitPortRequest, resubmitPort);
  yield takeLatest(activateHotlineRequest, activatingHotline);
  yield takeLatest(removeHotlineRequest, removeHotline);
  yield takeLatest(refreshLineRequest, refreshLine);
  yield takeLatest(portWithNewESNRequest, portWithSameESN);
  yield takeLatest(changeMDNAtCarrierRequest, changeMDNAtCarrier);
  yield takeLatest(cancelLinePortRequest, cancelLinePort);
  yield takeLatest(changeCostPlanRequest, changeCostPlan);
  yield takeLatest(getCDRReportRequest, getCDRData);
  yield takeLatest(downloadCDRZipRequest, downloadCDR);
  yield takeLatest(uploadCDRRequest, uploadCDR);
  yield takeLatest(syncSubscriptionDatesRequest, syncSubscriptions);
  yield takeLatest(activateShopifyLineRequest, activateShopifyLine);
  yield takeLatest(bulkChangeCostPlanRequest, bulkChangeCostPlan);
  yield takeLatest(submitCostPlanToPWGRequest, submitCostPlanToPWG);
  yield takeLatest(resetCostPlanOptimizationRequest, resetCostPlanOptimization);
}
