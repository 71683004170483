import { handleActions } from 'redux-actions';
import {
  assignSimCardToAgentsFailure,
  assignSimCardToAgentsRequest,
  assignSimCardToAgentsSuccess,
  bulkUpdateSimCardsFailure,
  bulkUpdateSimCardsRequest,
  bulkUpdateSimCardsSuccess,
  createSIMCardFailure,
  createSIMCardRequest,
  createSIMCardSuccess,
  deleteSimCardFailure,
  deleteSimCardRequest,
  deleteSimCardSuccess,
  getAvailableSimFailure,
  getAvailableSimRequest,
  getAvailableSimSuccess,
  getCarriersSelectDataFailure,
  getCarriersSelectDataRequest,
  getCarriersSelectDataSuccess,
  getCustomersSelectDataFailure,
  getCustomersSelectDataRequest,
  getCustomersSelectDataSuccess,
  getMasterAgentSelectFailure,
  getMasterAgentSelectRequest,
  getMasterAgentSelectSuccess,
  getSIMCardListFailure,
  getSIMCardListRequest,
  getSIMCardListSuccess,
  getSimSelectDataFailure,
  getSimSelectDataRequest,
  getSimSelectDataSuccess,
  getSubAgentSelectFailure,
  getSubAgentSelectRequest,
  getSubAgentSelectSuccess,
  updateSimCardFailure,
  updateSimCardRequest,
  updateSimCardSuccess,
  uploadESIMFailure,
  uploadESIMRequest,
  uploadESIMSuccess,
  uploadSimCardsFailure,
  uploadSimCardsRequest,
  uploadSimCardsSuccess,
} from './actions';

const initialState = {
  isGettingMasterAgentSelectFailure: false,
  isGettingSubAgentSelectFailure: false,
  masterAgentSelectData: [],
  subAgentSelectData: [],
  errorMessage: '',
  successMessage: '',
  isGettingCarrierSelectFailure: false,
  carrierSelectData: [],
  customersSelectData: [],
  isGettingCustomersSelectDataFailure: false,
  simCardList: [],
  simCardsCount: 0,
  isCreatingSIMCard: false,
  isCreatingSIMCardSuccess: false,
  isCreatingSIMCardFailure: false,
  isGettingSimCardList: true,
  isGettingSimCardListFailure: false,
  actives: 0,
  inActives: 0,
  runOut: 0,
  isDeletingSimCardSuccess: false,
  isDeletingSimCardFailure: false,
  startDate: 'Apr 17',
  endDate: 'May 17 (2023)',
  isAssigningToAgents: false,
  isAssigningToAgentsSuccess: false,
  isAssigningToAgentsFailure: false,
  isUpdatingSimCard: false,
  isUpdatingSimCardSuccess: false,
  isUpdatingSimCardFailure: false,
  isBulkUpdatingSimCards: false,
  isBulkUpdatingSimCardsSuccess: false,
  isBulkUpdatingSimCardsFailure: false,
  isUploadingSimCards: false,
  isUploadingSimCardsSuccess: false,
  isUploadingSimCardsFailure: false,
  isGettingAvailableSim: false,
  availableSim: {},
  isGettingSimSelectData: false,
  simSelectData: [],
};
const reducer = handleActions(
  {
    [getMasterAgentSelectRequest]: (state) => ({
      ...state,
      isGettingMasterAgentSelectFailure: false,
    }),
    [getMasterAgentSelectSuccess]: (state, { payload }) => ({
      ...state,
      masterAgentSelectData: payload.data,
      successMessage: payload.message,
    }),
    [getMasterAgentSelectFailure]: (state, { payload }) => ({
      ...state,
      isGettingMasterAgentSelectFailure: true,
      errorMessage: payload,
    }),
    [getSubAgentSelectRequest]: (state) => ({
      ...state,
      isGettingSubAgentSelectFailure: false,
    }),
    [getSubAgentSelectSuccess]: (state, { payload }) => ({
      ...state,
      subAgentSelectData: payload.data,
      successMessage: payload.message,
    }),
    [getSubAgentSelectFailure]: (state, { payload }) => ({
      ...state,
      isGettingSubAgentSelectFailure: true,
      errorMessage: payload,
    }),
    [getCarriersSelectDataRequest]: (state) => ({
      ...state,
      isGettingCarrierSelectFailure: false,
    }),
    [getCarriersSelectDataSuccess]: (state, { payload }) => ({
      ...state,
      successMessage: payload.message,
      carrierSelectData: payload.data,
    }),
    [getCarriersSelectDataFailure]: (state, { payload }) => ({
      ...state,
      errorMessage: payload,
      isGettingCarrierSelectFailure: true,
    }),
    [getCustomersSelectDataRequest]: (state) => ({
      ...state,
      isGettingCustomersSelectDataFailure: false,
    }),
    [getCustomersSelectDataSuccess]: (state, { payload }) => ({
      ...state,
      customersSelectData: payload.data,
      successMessage: payload.message,
    }),
    [getCustomersSelectDataFailure]: (state, { payload }) => ({
      ...state,
      isGettingCustomersSelectDataFailure: true,
      errorMessage: payload,
    }),
    [createSIMCardRequest]: (state) => ({
      ...state,
      isCreatingSIMCard: true,
      isCreatingSIMCardSuccess: false,
      isCreatingSIMCardFailure: false,
    }),
    [createSIMCardSuccess]: (state, { payload }) => ({
      ...state,
      isCreatingSIMCard: false,
      isCreatingSIMCardSuccess: true,
      successMessage: payload.message,
      simCardList: [payload.data, ...state.simCardList],
      simCardsCount: state.simCardsCount + 1,
    }),
    [createSIMCardFailure]: (state, { payload }) => ({
      ...state,
      isCreatingSIMCard: false,
      isCreatingSIMCardFailure: true,
      errorMessage: payload,
    }),
    [getSIMCardListRequest]: (state) => ({
      ...state,
      isGettingSimCardList: true,
      isGettingSimCardListFailure: false,
    }),
    [getSIMCardListSuccess]: (state, { payload }) => ({
      ...state,
      isGettingSimCardList: false,
      simCardList: payload.data.rows,
      simCardsCount: payload.data.count,
      actives: payload.data.activeCount,
      inActives: payload.data.inActiveCount,
      runOut: payload.data.runOutCount,
      startDate: payload.data.startDate,
      endDate: payload.data.endDate,
    }),
    [getSIMCardListFailure]: (state, { payload }) => ({
      ...state,
      isGettingSimCardList: false,
      isGettingSimCardListFailure: true,
      errorMessage: payload,
    }),
    [deleteSimCardRequest]: (state) => ({
      ...state,
      isDeletingSimCardSuccess: false,
      isDeletingSimCardFailure: false,
    }),
    [deleteSimCardSuccess]: (state, { payload }) => ({
      ...state,
      isDeletingSimCardSuccess: true,
      successMessage: payload.message,
      simCardList: state.simCardList.filter(
        (i) => !payload.data.includes(i.id)
      ),
      simCardsCount: state.simCardsCount - payload.data.length,
    }),
    [deleteSimCardFailure]: (state, { payload }) => ({
      ...state,
      isDeletingSimCardFailure: true,
      errorMessage: payload,
    }),
    [assignSimCardToAgentsRequest]: (state) => ({
      ...state,
      isAssigningToAgents: true,
      isAssigningToAgentsSuccess: false,
      isAssigningToAgentsFailure: false,
    }),
    [assignSimCardToAgentsSuccess]: (state, { payload }) => ({
      ...state,
      isAssigningToAgents: true,
      isAssigningToAgentsSuccess: true,
      successMessage: payload.message,
      simCardList: payload.data,
    }),
    [assignSimCardToAgentsFailure]: (state, { payload }) => ({
      ...state,
      isAssigningToAgents: false,
      isAssigningToAgentsFailure: true,
      errorMessage: payload,
    }),
    [updateSimCardRequest]: (state) => ({
      ...state,
      isUpdatingSimCard: true,
      isUpdatingSimCardSuccess: false,
      isUpdatingSimCardFailure: false,
    }),
    [updateSimCardSuccess]: (state, { payload }) => ({
      ...state,
      isUpdatingSimCard: false,
      isUpdatingSimCardSuccess: true,
      successMessage: payload.message,
      simCardList: state.simCardList.map((i) => {
        if (i.id === payload.data.id) {
          return { ...i, ...payload.data };
        } else {
          return i;
        }
      }),
    }),
    [updateSimCardFailure]: (state, { payload }) => ({
      ...state,
      isUpdatingSimCard: false,
      isUpdatingSimCardFailure: true,
      errorMessage: payload,
    }),
    [bulkUpdateSimCardsRequest]: (state) => ({
      ...state,
      isBulkUpdatingSimCards: true,
      isBulkUpdatingSimCardsSuccess: false,
      isBulkUpdatingSimCardsFailure: false,
    }),
    [bulkUpdateSimCardsSuccess]: (state, { payload }) => ({
      ...state,
      isBulkUpdatingSimCards: false,
      isBulkUpdatingSimCardsSuccess: true,
      successMessage: payload.message,
    }),
    [bulkUpdateSimCardsFailure]: (state, { payload }) => ({
      ...state,
      isBulkUpdatingSimCards: false,
      isBulkUpdatingSimCardsFailure: true,
      errorMessage: payload,
    }),
    [uploadSimCardsRequest]: (state) => ({
      ...state,
      isUploadingSimCards: true,
      isUploadingSimCardsSuccess: false,
      isUploadingSimCardsFailure: false,
    }),
    [uploadSimCardsSuccess]: (state, { payload }) => ({
      ...state,
      isUploadingSimCards: false,
      isUploadingSimCardsSuccess: true,
      successMessage: payload.message,
    }),
    [uploadSimCardsFailure]: (state, { payload }) => ({
      ...state,
      isUploadingSimCards: false,
      isUploadingSimCardsFailure: true,
      errorMessage: payload,
    }),
    [uploadESIMRequest]: (state) => ({
      ...state,
      isUploadingESIM: true,
      isUploadingESIMSuccess: false,
      isUploadingESIMFailure: false,
    }),
    [uploadESIMSuccess]: (state, { payload }) => ({
      ...state,
      isUploadingESIM: false,
      isUploadingESIMSuccess: true,
      successMessage: payload.message,
    }),
    [uploadESIMFailure]: (state, { payload }) => ({
      ...state,
      isUploadingESIM: false,
      isUploadingESIMFailure: true,
      errorMessage: payload,
    }),
    [getAvailableSimRequest]: (state) => ({
      ...state,
      isGettingAvailableSim: true,
    }),
    [getAvailableSimSuccess]: (state, { payload }) => ({
      ...state,
      isGettingAvailableSim: false,
      availableSim: payload.data,
      successMessage: payload.message,
    }),
    [getAvailableSimFailure]: (state, { payload }) => ({
      ...state,
      isGettingAvailableSim: false,
      errorMessage: payload,
    }),
    [getSimSelectDataRequest]: (state) => ({
      ...state,
      isGettingSimSelectData: true,
    }),
    [getSimSelectDataSuccess]: (state, { payload }) => ({
      ...state,
      isGettingSimSelectData: false,
      simSelectData: payload.data,
      successMessage: payload.message,
    }),
    [getSimSelectDataFailure]: (state, { payload }) => ({
      ...state,
      isGettingSimSelectData: false,
      errorMessage: payload,
    }),
  },
  initialState
);
export default reducer;
